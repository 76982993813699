import React from 'react';
import { connect } from 'react-redux';

import Pages from '@components/pages/Pages.tsx';
import { StoreT } from '@global/types.ts';

import RootI from './types.ts';

import renderPopups from './renders/renderPopups.tsx';
import renderTopBar from './renders/renderTopBar.tsx';
import pages from './static/pages.tsx';

const Styles = typeof window !== 'undefined' && require('./components/Styles.tsx').default;

class Root extends React.Component<RootI['props'], RootI['state']> implements RootI {
    parent: RootI['parent'];

    constructor(props: RootI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = pages;

    renderPopups = renderPopups;
    renderTopBar = renderTopBar;

    render() {
        const { rootInit } = this.props;

        return (
            <>
                {Styles && <Styles />}
                <div className="body__content">
                    {rootInit && (
                        <Pages
                            context={this}
                            pages={this.pages}
                            filter={(page) =>
                                !page.level || ['login', 'cheque', '404'].includes(page.name)
                            }
                        />
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        rootInit: state.rootInit,
        callFormSuccess: state.callFormSuccess,
    };
}

export default connect(mapStateToProps)(Root);
