import React from 'react';

import RootI from '../types.ts';

import Faq from '../pages/faq/Faq.tsx';
import Index from '../pages/index/Index.tsx';

const pages = {
    index: {
        render(this: RootI) {
            return <Index />;
        },
    },
    faq: {
        render(this: RootI) {
            return <Faq />;
        },
    },
} as const;

export default pages;
