import React from 'react';
import { connect } from 'react-redux';

import WaitHeader from './components/waitHeader/WaitHeader.tsx';

import IndexI from './types.ts';

class Index extends React.Component<IndexI['props'], IndexI['state']> implements IndexI {
    parent: IndexI['parent'];

    constructor(props: IndexI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <div ref={this.parent} className="index _FULL_W">
                <div className="index__section _FULL_W">
                    <WaitHeader />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Index);
