import React from 'react';

import I from '../types.ts';

const renderActions: I['renderActions'] = function () {
    return (
        <div className="menu__actions _FULL _COL">
            <div className="menu__action _ROW _ROW_V_CENTER _FULL _CLICK _code">
                <div className="menu__actionBack" />
                <div className="menu__actionInner _FULL _ROW">
                    <div className="menu__actionContent">Регистрация кода</div>
                    <div className="menu__actionContent _hide">Регистрация кода</div>
                </div>
            </div>
            <div className="menu__action _ROW _ROW_V_CENTER _FULL _CLICK _pyt">
                <div className="menu__actionBack" />
                <div className="menu__actionInner _FULL _ROW">
                    <div className="menu__actionContent">Акция в Пятерочке</div>
                    <div className="menu__actionContent _hide">Акция в Пятерочке</div>
                </div>
            </div>
            <div className="menu__action _ROW _ROW_V_CENTER _FULL _CLICK _lk">
                <div className="menu__actionBack" />
                <div className="menu__actionInner _FULL _ROW">
                    <div className="menu__actionContent">Личный кабинет</div>
                    <div className="menu__actionContent _hide">Личный кабинет</div>
                </div>
            </div>
        </div>
    );
};

export default renderActions;
