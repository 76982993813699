const pages = [
    {
        links: ['', undefined],
        name: 'index',
        content: 'Главная',
    },
    {
        links: ['404'],
        name: '404',
    },
    {
        links: ['cheque'],
        name: 'cheque',
        content: 'Регистрация чека с новинкой',
    },
    {
        links: ['rules'],
        name: 'rules',
        content: 'Правила Акции',
    },
    {
        links: ['prizes'],
        name: 'prizes',
        content: 'Призы',
    },
    {
        links: ['winners'],
        name: 'winners',
        content: 'Победители',
    },
    {
        links: ['faq'],
        name: 'faq',
        content: 'Вопрос-ответ',
    },
    {
        links: ['products'],
        name: 'products',
        content: 'Продукты-участники',
    },
] as const;

export default pages;
