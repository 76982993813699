import React from 'react';
import { connect } from 'react-redux';

import menuHandler from './methods/menuHandler.ts';
import scrollHandler from './methods/scrollHandler.ts';

import TopBarI from './types.ts';

import renderHead from './renders/renderHead.tsx';
import renderMenu from './renders/renderMenu.tsx';

class TopBar extends React.Component<TopBarI['props'], TopBarI['state']> implements TopBarI {
    parent: TopBarI['parent'];

    constructor(props: TopBarI['props']) {
        super(props);
        this.state = {
            isShowMenu: false,
        };

        this.scrollHandler = this.scrollHandler.bind(this);

        this.parent = React.createRef();
    }

    menuHandler = menuHandler;
    scrollHandler = scrollHandler;

    renderHead = renderHead;
    renderMenu = renderMenu;

    componentDidMount(): void {
        window.addEventListener('scroll', this.scrollHandler, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.scrollHandler, true);
    }

    render() {
        const { isShowMenu, isFix } = this.state;

        return (
            <div
                ref={this.parent}
                className={`topBar _FULL ${isShowMenu ? '_showMenu' : ''} ${isFix ? '_fix' : ''}`}
            >
                {this.renderHead()}
                {this.renderMenu()}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(TopBar);
