import React from 'react';

import Link from '@components/link/Link.tsx';

import I from '../types.ts';

const renderNav: I['renderNav'] = function () {
    const nav = this.getNav();

    return (
        <nav className="menu__nav _FULL">
            {nav.map((item) => {
                const ItemTag = item.pageName ? Link : 'a';
                const itemProps = item.pageName ? { pageName: item.pageName } : { href: item.href };

                return (
                    <div className={`menu__navItem _${item.name}`} key={item.name}>
                        <ItemTag className="menu__navItemLink" {...itemProps}>
                            {item.content}
                        </ItemTag>
                    </div>
                );
            })}
        </nav>
    );
};

export default renderNav;
