import React from 'react';

import I from '../types.ts';

const renderPrizes: I['renderPrizes'] = function () {
    return (
        <div className="menu__prizes _FULL_W _COL">
            <div className="menu__prizesTitle">
                Заходи в игровые шатры и&nbsp;участвуй в&nbsp;розыгрыше специального приза
            </div>
            <div className="menu__prizesItems _FULL_W _ROW">
                {this.prizes.map((prize, key) => (
                    <div className="menu__prizesItem" key={key}>
                        <div className="menu__prize _FULL_W _COL _COL_H_CENTER">
                            <div className="menu__prizeHead _COL _COL_CENTER">
                                <img
                                    src={require(`@media/${prize.image}`)}
                                    alt=""
                                    className="menu__prizeImage"
                                />
                            </div>
                            <div className="menu__prizeContent">{prize.title}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default renderPrizes;
