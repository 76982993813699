import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Media from '@components/media/Media.tsx';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    return (
        <div className="topBar__head _SECTION">
            <div className="topBar__headInner _INNER">
                <div
                    className="topBar__stateButton _CLICK"
                    onClick={() => {
                        this.menuHandler();
                    }}
                >
                    <div className="topBar__stateButtonItem _FULL _ROW _ROW_CENTER" />
                    <div className="topBar__stateButtonItem _FULL _ROW _ROW_CENTER" />
                    <div className="topBar__stateButtonItem _FULL _ROW _ROW_CENTER" />
                </div>
                <div className="topBar__logo">
                    <Icon name="logo" />
                </div>
                <Media current="desktop">
                    <div className="topBar__buttons _ROW">
                        <div className="topBar__button _ROW _ROW_CENTER _CLICK _purple">
                            <div className="topBar__buttonContent">Регистрация кода</div>
                            <div className="topBar__buttonContent _hide">Регистрация кода</div>
                        </div>
                        <div className="topBar__button _ROW _ROW_CENTER _CLICK _pink">
                            <div className="topBar__buttonContent">Личный кабинет</div>
                            <div className="topBar__buttonContent _hide">Личный кабинет</div>
                        </div>
                        <div className="topBar__button _ROW _ROW_CENTER _CLICK _red _logo">
                            <img
                                src={require('@media/logo-5ka.svg').default}
                                alt=""
                                className="topBar__buttonLogo"
                            />
                            <div className="topBar__buttonContent">акция в пятерочке</div>
                            <div className="topBar__buttonContent _hide">акция в пятерочке</div>
                        </div>
                    </div>
                </Media>
                <Media current="mobile">
                    <div className="topBar__user _CLICK"></div>
                </Media>
            </div>
        </div>
    );
};

export default renderHead;
