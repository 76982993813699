import React from 'react';
import { connect } from 'react-redux';

import FaqI from './types.ts';

class Faq extends React.Component<FaqI['props'], FaqI['state']> implements FaqI {
    parent: FaqI['parent'];

    constructor(props: FaqI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return <div ref={this.parent} className="faq _SECTION"></div>;
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Faq);
